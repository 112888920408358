import React from "react";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import GraphqlAdopters from "../components/GraphqlTools/GraphqlAdopters";

function GraphqlAdoptersPage() {

  let description = "GraphQL Adopters and GraphQL Foundation Members";
  return (
    <Layout>
      <SEO description={description} title={description}>
      </SEO>
      <Section>
              <div style={{ marginTop: "50px" }}>
              <Headings.h1>{description}</Headings.h1>
              <br/>
              <GraphqlAdopters/>
          </div>
      </Section>
    </Layout>
  );
}

export default GraphqlAdoptersPage;
