import React from 'react';

import Card from '@bit/semantic-org.semantic-ui-react.card'
import Image from '@bit/semantic-org.semantic-ui-react.image'
import Icon from '@bit/semantic-org.semantic-ui-react.icon'
import Statistic from '@bit/semantic-org.semantic-ui-react.statistic'
import Grid from '@bit/semantic-org.semantic-ui-react.grid'

import {graphql, useStaticQuery} from "gatsby";

const siteAdoptersQuery = graphql`
  {
    allGraphqlListJson(filter: {category: {in: ["GraphQL Adopter", "GraphQL Foundation Member"]}}) {
        edges {
          node {
            id
            name
            category
            twitter
            href {
              name
              base
            }
            path
            stars
            organization
            project
            repo_url
            homepage_url
            description
            crunchbase
            contributorsCount
            organization
            headquarters
          }
        }
      }
  }
`;

const CardsList = (props) => {
    console.log("site" + props.cardsType + "Query")
    const results = useStaticQuery(siteAdoptersQuery);
    var allGraphqlListJson = results.allGraphqlListJson.edges;
    // console.log(JSON.stringify(allGraphqlListJson, null, 2))

    return (
        <>
            { allGraphqlListJson.map(api => {
                const path = require('../../../content/landscape/logos/' + api.node.href.base);
                return (
                <>
                    <Card color='blue' key={api.node.id}>
                        <Card.Content>
                            <a href={api.node.homepage_url} target="new">
                                <Image floated='right' size='tiny' alt="{api.node.name}" src={path}/>
                            </a>
                            <Card.Header>
                                <a href={api.node.homepage_url} target="new">{api.node.name}</a>
                            </Card.Header>
                            <Card.Meta><b>{api.node.description}</b></Card.Meta>
                            <Card.Description>

                                <Statistic.Group widths='one' size="tiny">
                                    <Statistic>
                                        <Statistic.Value>
                                            <Icon name='map'/><br/>{api.node.headquarters}
                                        </Statistic.Value>
                                    </Statistic>

                                </Statistic.Group>
                            </Card.Description>
                        </Card.Content>

                        <Card.Content extra>
                            <Grid>
                                <Grid.Row columns={4}>
                                    <Grid.Column>
                                        <a href={api.node.twitter}>Twitter</a>
                                    </Grid.Column>
                                    <Grid.Column>
                                    <a href={api.node.repo_url}>Github</a>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <a href={api.node.crunchbase}>CrunchBase</a>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card.Content>
                    </Card>
                </>
            )})}
        </>
    )
}


const GraphqAdopters: React.FC = () => {

    return (
        <div>
            <Card.Group stackable itemsPerRow={4}>
                <CardsList cardsType="Projects"/>
            </Card.Group>

        </div>
    )
};

export default GraphqAdopters;
